<template>
  <div class="b-uploader">
    <label
      :for="id"
      class="bg-transparent hover:opacity-60 font-medium py-1.5 px-4 rounded-lg border border-white text-white mx-4 cursor-pointer text-xs"
    >
      {{ $t('CHOOSE_FILE') }}
    </label>
    <div
      v-if="suggestSizeText"
      class="text-sm mr-4"
    >
      {{ suggestSizeText }}
    </div>
    <div
      v-if="fileName"
      class="text-xl truncate"
    >
      {{ fileName }}
    </div>
    <input
      class="b-uploader__file-input"
      v-if="resetControl"
      :id="id"
      :accept="accept"
      type="file"
      :multiple="false"
      v-bind="$attrs"
      @change="onInputChange"
    />
    <!-- <div class="b-uploader__preview" v-if="url">
      <img
        class="b-uploader__preview__img"
        :src="url"
        alt=""
        @error="onImgError"
        ref="image"
      />
      <div class="b-uploader__preview__actions">
        <button
          @click="show"
          type="button"
          class="outline-none border-none bg-transparent focus:outline-none"
        >
          <img src="/img/icons/eye.svg" alt="" />
        </button>
        <button
          @click="onDeleteImg"
          type="button"
          class="outline-none border-none bg-transparent focus:outline-none"
        >
          <img src="/img/icons/trash.svg" alt="" />
        </button>
        <div>
          <viewer
            :options="options"
            :images="images"
            rebuild
            @inited="inited"
            class="viewer"
            ref="viewer"
          >
            <img class="hidden" v-for="src in images" :key="src" :src="src" />
          </viewer>
        </div>
      </div>
    </div> -->
    <div
      v-if="errorMaxSize"
      class="text-red-500 mt-2 text-sm leading-4"
    >
      {{ errorMaxSize }}
    </div>
    <div
      v-else-if="error"
      class="text-red-500 mt-2 text-sm leading-4"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash-es';
import { MAX_IMAGE_SIZE } from '@/constants/common.constant';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'fileInput',
    },

    value: {
      type: Object,
      default: () => ({}),
    },
    error: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/gif',
    },
    imgUrl: {
      type: String,
      default: '',
    },
    maxSize: {
      type: Number,
      default: MAX_IMAGE_SIZE,
    },
    shouldLimitFileSize: {
      type: Boolean,
      default: true,
    },
    suggestSizeText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      resetControl: true,
      fileSelected: this.value || {},
      url: '',
      index: null,
      errorMaxSize: '',
    };
  },
  computed: {
    images() {
      return [this.url];
    },
    fileName() {
      if (this.fileSelected) {
        return this.fileSelected.name;
      }
      return '';
    },
  },
  mounted() {
    this.errorMaxSize = '';
  },

  methods: {
    onResetInput() {
      this.resetControl = false;
      this.$nextTick(() => {
        this.resetControl = true;
      });
    },
    onInputChange(e) {
      this.errorMaxSize = '';
      const element = e.target;
      console.debug({ element });
      const files = [...(element.files || [])];
      const newFiles = files.map((file) => ({
        file,
        name: file.name,
        size: file.size,
        type: file.type,
      }));
      if (this.shouldLimitFileSize && newFiles[0].file.size > this.maxSize) {
        this.errorMaxSize = `The image size must be lower than or equal ${
          this.maxSize / 1000000
        }MB`;
        return;
      }
      this.fileSelected = { ...newFiles[0] };
      this.url = URL.createObjectURL(this.fileSelected.file);
      this.onResetInput();

      this.$emit('input', this.fileSelected);
    },
    onDeleteImg() {
      this.url = '';
      this.fileSelected = {};
      this.$emit('input', this.fileSelected);
    },

    onImgError() {
      console.log('ERRROR');
      this.url = '';
    },
  },
  watch: {
    value(newVal) {
      if (isEqual(this.fileSelected, newVal)) return;
      this.fileSelected = { ...newVal };
    },
    imgUrl(val) {
      this.url = val;
    },
  },
};
</script>

<style lang="scss">
.b-uploader {
  @apply flex items-center;
  &__file-input {
    @apply fixed opacity-0 overflow-hidden bg-transparent;

    top: -1px;
    left: -1px;
    z-index: -1;
    width: 1px;
    height: 1px;
  }
  &__preview {
    background: #dbdbdb;
    border: 1px solid #434343;
    @apply p-2 w-max;
    @apply relative absolute;
    &__img {
      width: 88px;
      height: 88px;
      @apply object-cover;
    }
    &__actions {
      width: 88px;
      height: 88px;
      @apply absolute;
      background: rgba(20, 20, 20, 0.65);
      @apply flex items-center justify-center;
      @apply top-2;
      button {
        &:first-child {
          @apply mr-2;
        }
      }
    }
  }
}
</style>
