<template>
  <div class="project-view-section w-full bg-section-bg p-8">
    <div class="text-xl font-semibold w-full border-white border-b mb-4 pb-2">
      {{ $t(title) }}
    </div>
    <section class="view-info px-0 md:px-8">
      <div
        v-for="item in columns"
        :key="`project-view-section-${item.title}-${item.key}`"
      >
        <div class="flex justify-between mb-4 items-start">
          <div
            v-if="!item.scopedSlots || !item.scopedSlots.title"
            class="text-white font-light project-view-section__title text-xl"
          >
            {{ $t(item.title) }}
          </div>
          <div
            v-else
            class="text-white font-light project-view-section__title text-xl"
          >
            <slot
              :name="item.scopedSlots.title"
              :value="dataSource[item.key]"
              :record="dataSource"
            >
            </slot>
          </div>

          <div
            v-if="!item.scopedSlots || !item.scopedSlots.value"
            class="font-semibold col-span-3 text-xl project-view-section__content truncate"
          >
            {{ dataSource[item.key] }}
          </div>
          <div
            v-else
            class="font-semibold col-span-3 project-view-section__content text-xl"
          >
            <slot
              :name="item.scopedSlots.value"
              :value="dataSource[item.key]"
              :record="dataSource"
            >
            </slot>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ProjectViewSection',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    // columns config available: title, key, scopedSlots (optional, for now changes: value, title)
    // if scopedSlots is not specified, value and title will be contain in <div></div> tag
    columns: {
      type: Array,
      default: () => [],
    },
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.project-view-section {
  &__title {
    min-width: 260px;
    // max-width: 250px;
    @apply mr-6;
  }
  &__content {
    @apply flex-1;
  }
}
</style>
